import axios from "axios";
import { useContext, useEffect, useMemo, useState } from "react";
import { getChatMessagesUrl } from "@/components/url";
import WebsocketContext from "@/contexts/WebsocketContext";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";
import ChatInfoContext from "../../ChatInfoContext";

const LIMIT = 15;
const MORE_LIMIT = 1;

interface FeedbackObj {
  is_helpful: null | boolean;
}

export type Feedback = FeedbackObj | null;

export interface Message {
  id: string;
  sender: any;
  text: string;
  created_at: string;
  feedback: Feedback;
}

type MessageList = Message[];
type MessageUidList = string[];

const useLoadMessageList = () => {
  const { chatInfo, updateChatInfo } = useContext(ChatInfoContext);
  const id = chatInfo?.uid;

  const [messages, setMessages] = useState<MessageUidList>([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false); // loading

  const { lastUpdate } = useContext(WebsocketContext);

  const cacheScroll = useMemo(() => ({ scrollTop: undefined, scrollLoading: false }), []);
  const cacheData = useMemo(() => ({ data: [] as MessageList, loadMore: true, dataMap: {} as any }), []);

  const combineData = (newList: MessageList, list: MessageList, isPush = false) => {
    if (isPush) {
      // 取历史数据，push
      let index = list.length - 1;
      while (index >= 0) {
        if (list[index].id === newList[0].id) {
          list.splice(index, list.length - index, ...newList);
          break;
        } else {
          index--;
        }
      }
    } else {
      // 取最新的数据，pop
      let index = 0;
      while (index < list.length) {
        if (list[index].id === newList[newList.length - 1].id) {
          list = list.slice(+1);
          list = newList.concat(list);
          break;
        } else {
          index++;
        }
      }
    }
    updateMessage(list);
  };

  const updateMessage = (newList: MessageList) => {
    cacheData.data = newList;
    cacheData.dataMap = newList.reduce((o, item) => {
      o[item.id] = item;
      return o;
    }, {} as any);
    setMessages([...newList.map(n => `${n.id}`)].reverse());
  };

  const isTotalRepeat = (newList: MessageList, list: MessageList) =>
    list.find(n => n.id === newList[0].id) && list.find(n => n.id === newList[newList.length - 1].id);

  const hasRepeat = (newList: MessageList, list: MessageList) =>
    newList.find(n => n.id === list[0].id || n.id === list[list.length - 1].id);

  const loadAMessage = (mId = "") => {
    axios
      .get(getChatMessagesUrl(id, mId))
      .then(axiosResponseHandler)
      .then(r => {
        const newItem = r.data.data;
        cacheData.dataMap[mId] = newItem;
        setMessages(prev => [...prev]);
      })
      .catch(axiosError);
  };

  const loadMessages = ({ offset = 0, limit = LIMIT }) => {
    if (!id) return;
    if (offset) {
      if (!cacheData.loadMore) return; // 历史数据加载完了，不能再加载了
      cacheScroll.scrollLoading = true;
      setLoadingMore(true);
    }
    if (!cacheData.data?.length) setInitialLoading(true);
    axios
      .get(getChatMessagesUrl(id), { params: { offset, limit } })
      .then(axiosResponseHandler)
      .then((r: any) => {
        const newList = r.data.data || [];
        if (!newList.length) return;

        const { data } = cacheData;
        if (!data.length) {
          updateMessage(newList);
          return;
        }

        if (isTotalRepeat(newList, data)) {
          if (offset) cacheData.loadMore = false; // 历史数据加载完了
          return;
        }

        if (hasRepeat(newList, data)) {
          combineData(newList, data, !!offset);
        } else {
          // 无重叠，多取数据
          loadMessages({ offset, limit: limit + MORE_LIMIT });
        }
      })
      .catch(axiosError)
      .finally(() => {
        cacheScroll.scrollLoading = false;
        setLoadingMore(false);
        cacheScroll.scrollTop = undefined;
        setInitialLoading(false);
      });
  };

  // 加载历史数据
  const loadHistoryPre = () => {
    const { data } = cacheData;
    loadMessages({ offset: data.length - 2 }); // 保证数据有重叠
  };

  useEffect(() => {
    loadMessages({});
  }, [id]);

  useEffect(() => {
    if (lastUpdate) {
      const { id } = lastUpdate;
      const { dataMap, data } = cacheData;
      if (dataMap[id]) loadAMessage(id); // 更新单条消息
      else {
        loadMessages({ limit: data?.length ? MORE_LIMIT + 1 : LIMIT }); // 加载最新的消息
      }
      updateChatInfo();
    }
  }, [lastUpdate]);

  return {
    cacheData,
    cacheScroll,
    loadingMore,
    loadHistoryPre,
    initialLoading,
    messages,
    loadAMessage,
  };
};

export default useLoadMessageList;
