import React, { ReactNode, useContext, useMemo, useState } from "react";
import ErrorCode from "@/components/constants/ErrorCode";
import WebsocketType from "@/components/constants/WebsocketType";
import { getWebsocketUrl } from "@/components/url";
import { loginErrorHandler } from "@/utils/axiosError";
import UserInfoContext from "./UserInfoContext";

export interface LastUpdate {
  chat_uid: string;
  thread_uid: string;
  id: any;
}
interface ContextValues {
  lastUpdate?: LastUpdate;
  unLockThreadUid: string;
}
const WebsocketContext = React.createContext<ContextValues>({
  lastUpdate: undefined,
  unLockThreadUid: "",
});

export default WebsocketContext;

interface IProps {
  children?: ReactNode;
}

export const WebsocketContextProvider: React.FC<IProps> = ({ children }) => {
  const [reconnectKey, setReconnectKey] = useState(0);

  const socket = useMemo(() => new WebSocket(getWebsocketUrl()), [reconnectKey]);

  const [lastUpdate, setLastUpdate] = useState();
  const [unLockThreadUid, setUnlockThreadUid] = useState("");

  const { updateUser } = useContext(UserInfoContext);

  const onError = (e: any) => {
    const code = e?.reason ? Number(e.reason) : 0;
    if (ErrorCode.loginErrors.includes(code)) {
      loginErrorHandler(code);
    } else {
      if (reconnectKey < 5) {
        setReconnectKey(c => c + 1);
      }
    }
    console.log("onError", e);
  };

  socket.onopen = function (e) {
    console.log("onopen", e);
  };

  socket.onclose = function (e) {
    onError(e);
    console.log("onclose", e);
  };

  socket.onmessage = function (e) {
    const { type, data = {} } = JSON.parse(e.data) || {};
    if (!type) return;

    switch (type) {
      case WebsocketType.MESSAGE:
        if (data?.chat_uid) {
          setLastUpdate(data);
        }
        return;
      case WebsocketType.QUOTA:
        updateUser();
        return;
      case WebsocketType.UNLOCK_THREAD:
        data?.uid && setUnlockThreadUid(data.uid);
        return;
      default:
        return;
    }
  };

  socket.onerror = function (e) {
    onError(e);
    console.log("onerror", e);
  };

  return <WebsocketContext.Provider value={{ lastUpdate, unLockThreadUid }}>{children}</WebsocketContext.Provider>;
};
