import { CloudDownloadOutlined } from "@ant-design/icons";
import { Flex, Tooltip } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ErrorCode from "@/components/constants/ErrorCode";
import Loading from "@/components/Loading";
import PPTXPreviewModal from "@/components/PPTXPreviewModal";
import { getFileDownloadUrl, getFilesUrl } from "@/components/url";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";
import { downloadFileFromUrl } from "@/utils/common";

import styles from "./index.module.scss";

interface Props {
  id: string;
}

export interface FileData {
  uid?: string;
  file_name?: string;
  file_type?: string; // pptx
  created_at?: string;
  updated_at?: string;
  download?: boolean; // canDownload
  deleted?: boolean;
  demo_file?: number;
}

const AttachmentFile: React.FC<Props> = ({ id = "" }) => {
  const [data, setData] = useState<FileData>({});
  const [loading, setLoading] = useState(false);
  const [viewUrl, setViewUrl] = useState("");

  const loadFile = () => {
    setLoading(true);
    axios
      .get(getFilesUrl(id))
      .then(axiosResponseHandler)
      .then((r: any) => {
        setData(r.data.data);
      })
      .catch(r => {
        const { code } = r;
        if (code === ErrorCode.FILE_DELETED) {
          setData({ deleted: true });
        } else {
          axiosError(r);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadFile();
  }, [id]);

  const renderType = () => {
    if (data.deleted) return <div className={styles.deletedCsvType}>F</div>;

    const type = data?.file_type?.toLocaleLowerCase() || "";
    if (type.startsWith("p")) return <div className={styles.pptType}>P</div>;
    if (type.startsWith("x") || type.startsWith("c")) return <div className={styles.csvType}>X</div>;
  };

  const onGetFileLInk = (isView = false) => {
    if (!data) return;
    data &&
      axios
        .post(getFileDownloadUrl(id))
        .then(axiosResponseHandler)
        .then((r: any) => {
          const url = r.data.data.url;
          if (isView) setViewUrl(url);
          else downloadFileFromUrl(url, `${data.file_name}.${data.file_type}`);
        })
        .catch(axiosError);
  };

  const canPreview = data?.download;

  return (
    <>
      <Flex className={styles.container} justify="space-between">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Tooltip title={canPreview ? "Preview" : ""}>
              <div
                style={{ cursor: canPreview ? "pointer" : "unset", display: "flex", flex: "1", overflow: "hidden" }}
                onClick={canPreview ? () => onGetFileLInk(true) : undefined}
              >
                {renderType()}
                <div style={{ flex: 1, overflow: "hidden" }}>
                  <p className={styles.name}>{data.deleted ? "File Expired" : data.file_name}</p>
                  {!data.deleted && <p className={styles.type}>{data.file_type}</p>}
                </div>
              </div>
            </Tooltip>
            {data?.download && (
              <Tooltip title="Download">
                <CloudDownloadOutlined onClick={() => onGetFileLInk()} style={{ color: "rgba(0, 0, 0, 0.45)" }} />
              </Tooltip>
            )}
          </>
        )}
      </Flex>
      {viewUrl && <PPTXPreviewModal url={viewUrl} onCancel={() => setViewUrl("")} title={`${data.file_name}.${data.file_type}`} />}
    </>
  );
};

export default AttachmentFile;
