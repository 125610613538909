import axios from "axios";
import _ from "lodash";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorCode from "@/components/constants/ErrorCode";
import { getChatsUrl, getFilesUrl } from "@/components/url";
import ChatsContext from "@/contexts/ChatsContext";
import WebsocketContext from "@/contexts/WebsocketContext";
import { FileDataOption } from "@/hooks/useGetFileList";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";
import { getFormatFileDataOption } from "@/utils/common";

interface IProps {
  children?: ReactNode;
}

interface Thread {
  files: string[];
  uid: string;
  lock: boolean;
}

export interface ChatInfo {
  last_thread: Thread;
  title: string;
  uid: string;
  is_active?: boolean; // 是否已经失效
}

interface ContextValues {
  chatInfo?: ChatInfo;
  updateChatInfo: () => void;
  isLoading: boolean;
  sending: boolean;
  setSending: any;
  selectedFile?: FileDataOption;
  setSelectedFile: any;
}

const ChatInfoContext = React.createContext<ContextValues>({
  chatInfo: undefined,
  updateChatInfo: _.noop,
  isLoading: false,
  sending: false,
  setSending: _.noop,
  selectedFile: undefined,
  setSelectedFile: _.noop,
});

export default ChatInfoContext;

export const ChatInfoContextProvider: React.FC<IProps> = ({ children }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [chatInfo, setChatInfo] = useState<ChatInfo>();
  const [sending, setSending] = useState(false); // 是否发了一条新的消息
  const [selectedFile, setSelectedFile] = useState<FileDataOption>(); // 最后一个 thread 里面的文件数据，用来填充 thread 或判断是否过期

  const { unLockThreadUid } = useContext(WebsocketContext);
  const { updateTitle } = useContext(ChatsContext);

  const isLoading = id !== chatInfo?.uid;

  const updateChatInfo = () => {
    if (!id) return;
    axios
      .get(getChatsUrl(id))
      .then(axiosResponseHandler)
      .then(r => {
        const newInfo = r.data.data as ChatInfo;
        const { last_thread } = newInfo;
        setChatInfo(newInfo);
        updateTitle(newInfo?.title, newInfo?.uid); // 更新菜单左侧列表数据
        if (sending && !last_thread.lock) {
          setSending(false); // 重置 sending 状态
        } else if (last_thread.lock) {
          setSending(true);
        }
      })
      .catch(e => {
        const { code } = e;
        if (code === ErrorCode.CHAT_DELETED) {
          navigate("/chats");
        }
        axiosError(e);
        setSending(false); // 报错情况下，置为可以发送消息
      });
  };

  useEffect(() => {
    if (id) {
      setSelectedFile(undefined);
      setChatInfo(undefined);
      setSending(false);
      updateChatInfo();
    }
  }, [id]);

  // 根据最新的 chat 信息进行更换选中的文件信息
  useEffect(() => {
    const chatFileUid = chatInfo?.last_thread?.files?.[0];
    // 只在初始化时候加载初始化的 file 文件
    if (chatFileUid && !selectedFile) {
      axios
        .get(getFilesUrl(chatFileUid))
        .then(axiosResponseHandler)
        .then(r => {
          setSelectedFile(getFormatFileDataOption(r.data.data));
        })
        .catch(e => {
          const { code } = e;
          if (code === ErrorCode.FILE_DELETED) {
            setSelectedFile({ deleted: true });
          } else {
            axiosError(e);
          }
        });
    }
  }, [chatInfo, selectedFile]);

  useEffect(() => {
    if (unLockThreadUid) updateChatInfo();
  }, [unLockThreadUid]);

  return (
    <ChatInfoContext.Provider value={{ chatInfo, updateChatInfo, sending, setSending, selectedFile, setSelectedFile, isLoading }}>
      {children}
    </ChatInfoContext.Provider>
  );
};
