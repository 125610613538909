export const getCacheValue =(v?:any) =>{
    let value = v;
    return function(v?:any){
        return v=== undefined ? value: (value =v);
      }
}

export const uuidV4 = () =>
  "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16).toLowerCase();
  });
