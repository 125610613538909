import React from "react";

interface Props {
  style?: React.CSSProperties;
}

const SenderArrow: React.FC<Props> = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Send Button"
      d="M8.01985 8.4981L1.70104 9.109L0.038266 14.4269C-0.0608777 14.7413 0.0363776 15.085 0.286586 15.3003C0.535848 15.5155 0.889939 15.5609 1.18644 15.4164L15.0344 8.71059C15.3054 8.5784 15.4772 8.30363 15.4772 8.00242C15.4772 7.70121 15.3053 7.42645 15.0344 7.29425L1.19587 0.583602C0.899377 0.439136 0.545299 0.484459 0.296013 0.699742C0.0457835 0.915027 -0.0514607 1.25778 0.0476931 1.57221L1.71046 6.89006L8.01696 7.50191C8.2719 7.5274 8.46641 7.74174 8.46641 7.99762C8.46641 8.25352 8.27191 8.46784 8.01696 8.49334L8.01985 8.4981Z"
      fill="currentColor"
    />
  </svg>
);

export default SenderArrow;
