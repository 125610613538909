import ErrorModule from "./ErrorModule";

export default class ErrorCode {
  // 登录和账号相关 16...
  static NOT_LOGIN_IN = ErrorModule.AUTH | 1;  // 未登录 16777217
  static SESSION_EXPIRED = ErrorModule.AUTH | 2; // 会话过期，需要重新登录 16777218
  static ACCOUNT_NOT_FOUND = ErrorModule.AUTH | 3; // 账号不存在 16777219
  static LOGIN_FROM_OTHER_DEVICE = ErrorModule.AUTH | 4; // 账号已在其他设备登录 16777220
  static UPLOAD_QUOTA_EXCEEDED = ErrorModule.AUTH | 5; // 上传配额达到上限 16777221
  static GENERATE_QUOTA_EXCEEDED = ErrorModule.AUTH | 6; // ppt 配额达到上限 16777222
  static INVALID_REQUEST_PARAM = ErrorModule.AUTH | 7; // 请求参数错误 16777223

  // 聊天相关 33...
  static CHAT_DELETED = ErrorModule.CHAT | 1; // 聊天已删除  33554433
  static CHAT_EXPIRED = ErrorModule.CHAT | 2; // 聊天已过期 
  static THREAD_NOT_FOUND = ErrorModule.CHAT | 3; // 聊天线程不存在
  static MESSAGE_NOT_FOUND = ErrorModule.CHAT | 4; // 消息不存在
  static THREAD_LOCKED = ErrorModule.CHAT | 5; // 聊天线程已锁定
  static FEEDBACK_NOT_FOUND = ErrorModule.CHAT | 6; // 反馈不存在
  static FEEDBACK_SUBMITTED = ErrorModule.CHAT | 7; // 反馈已提交

  // 文件相关 50...
  static FILE_SIZE_EXCEEDED = ErrorModule.FILE | 1; // 文件大小超出限制  50331649
  static FILE_DELETED = ErrorModule.FILE | 2; // 文件已删除（文件过期或者用户删除） 50331650
  static FILE_PERMISSION_DENY = ErrorModule.FILE | 3; // 文件权限不足 50331651
  static FILE_NAME_DUPLICATED = ErrorModule.FILE | 4; //文件名重复 50331652
  static FILE_NOT_SUPPORT = ErrorModule.FILE | 5; // 不支持的文件类型 50331653
  static FILE_PROCESS_FAIL = ErrorModule.FILE | 6; // 文件处理失败，并已经被删除 50331654

  static loginErrors = [this.NOT_LOGIN_IN, this.SESSION_EXPIRED, this.ACCOUNT_NOT_FOUND,this.LOGIN_FROM_OTHER_DEVICE];

  static uploadFileErrors = [this.FILE_SIZE_EXCEEDED,this.FILE_NAME_DUPLICATED,this.FILE_NOT_SUPPORT,this.UPLOAD_QUOTA_EXCEEDED,this.GENERATE_QUOTA_EXCEEDED];

  static quotaExceededErrors = [this.UPLOAD_QUOTA_EXCEEDED,this.GENERATE_QUOTA_EXCEEDED];

  static needLoginCodes = [this.NOT_LOGIN_IN, this.SESSION_EXPIRED, this.ACCOUNT_NOT_FOUND];

  static getText = (code = 0) => {

    // 登录和账号相关
   if (code === this.NOT_LOGIN_IN) return "Not Logged In";
   if (code === this.SESSION_EXPIRED) return "Session Expired, Please Log In Again";
   if (code === this.ACCOUNT_NOT_FOUND) return "Account Not Found";
   if (code === this.LOGIN_FROM_OTHER_DEVICE) return "Account Logged In From Another Device.";
   if (code === this.UPLOAD_QUOTA_EXCEEDED) return "File Upload Quota Exceeded.";
   if (code === this.GENERATE_QUOTA_EXCEEDED) return "PPT Generation Quota Exceeded.";
   if (code === this.INVALID_REQUEST_PARAM) return "Invalid request param.";

   // 聊天相关
   if (code === this.CHAT_DELETED) return "Chat Deleted";
   if (code === this.CHAT_EXPIRED) return "Chat Expired";
   if (code === this.THREAD_NOT_FOUND) return "Thread Not Found";
   if (code === this.MESSAGE_NOT_FOUND) return "Message Not Found";
   if (code === this.THREAD_LOCKED) return "The chat is locked";
   if (code === this.FEEDBACK_NOT_FOUND) return "The feedback not found";
   if (code === this.FEEDBACK_SUBMITTED) return "The feedback submitted.";

   // 文件相关
   if (code === this.FILE_SIZE_EXCEEDED) return "File Size Exceeds The Allowed Limit.";
   if (code === this.FILE_DELETED) return "File Deleted.";
   if (code === this.FILE_PERMISSION_DENY) return "File Permission Denied.";
   if (code === this.FILE_NAME_DUPLICATED) return "File Name Already Exists.";
   if (code === this.FILE_NOT_SUPPORT) return "The File Type Is Not Supported.";
   if (code === this.FILE_PROCESS_FAIL) return "File processing failed.";
   return "";
 };
}
