import { Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";

import styles from "./index.module.scss";

const AutoOverflowTip = ({ title, displayTitle = "", tipStyle = {}, ...rest }) => {
  const containerRef = useRef();
  const noTipRef = useRef();

  const [showTip, setShowTip] = useState(false);

  useEffect(() => {
    setShowTip(false);
  }, [title]);

  useEffect(() => {
    if (noTipRef.current && containerRef.current) setShowTip(noTipRef.current.offsetWidth > containerRef.current.offsetWidth);
  });

  const extraAttr = { dangerouslySetInnerHTML: { __html: displayTitle || title } };

  return (
    <div className={styles.main}>
      {showTip ? (
        <Tooltip title={title} mouseLeaveDelay={0.1} {...rest}>
          <div className={styles.tipText} style={tipStyle} {...extraAttr} />
        </Tooltip>
      ) : (
        <div className={styles.noTipContainer} ref={containerRef}>
          <div ref={noTipRef} className={styles.noTip} style={tipStyle} {...extraAttr} />
        </div>
      )}
    </div>
  );
};

export default AutoOverflowTip;
