import { EditOutlined } from "@ant-design/icons";
import { Input } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { getChatsUrl } from "@/components/url";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";
import ChatInfoContext from "../ChatInfoContext";

const ChatTitle: React.FC = () => {
  const { chatInfo, updateChatInfo } = useContext(ChatInfoContext);

  const [edit, setEdit] = useState(false);
  const [v, setV] = useState(chatInfo?.title);

  useEffect(() => setV(chatInfo?.title), [chatInfo?.title]);

  const updateTitleInDB = (v: string) => {
    if (!chatInfo) return;
    axios
      .patch(getChatsUrl(chatInfo.uid), { title: v })
      .then(axiosResponseHandler)
      .then(() => {
        updateChatInfo();
      })
      .catch(axiosError);
  };

  useEffect(() => {
    if (edit == false) {
      if (v && v !== chatInfo?.title) {
        updateTitleInDB(v);
      } else {
        setV(chatInfo?.title); // 用户清空 title 或者没有改 title 就把数据设回去
      }
    }
  }, [edit, v]);

  const renderInput = () => (
    <Input
      autoFocus
      style={{ width: "400px" }}
      value={v}
      onBlur={() => setEdit(false)}
      onPressEnter={() => setEdit(false)}
      onChange={e => {
        setV(e.target.value);
      }}
    />
  );

  return (
    <div>
      {edit ? (
        renderInput()
      ) : (
        <>
          {v}
          <EditOutlined onClick={() => setEdit(true)} style={{ marginLeft: "6px", fontSize: "12px", color: "rgba(0, 0, 0, 0.45)" }} />
        </>
      )}
    </div>
  );
};

export default ChatTitle;
