import React from "react";

interface Props {
  style?: React.CSSProperties;
}

const QuestionBulb: React.FC<Props> = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none">
    <path
      d="M8 2.125C5.79062 2.125 4 3.91562 4 6.125C4 7.57031 4.77188 8.87969 6.00156 9.59062L6.5625 9.91562V11.75H9.4375V9.91562L9.99844 9.59062C11.2281 8.87969 12 7.57031 12 6.125C12 3.91562 10.2094 2.125 8 2.125Z"
      fill="#FFE7BA"
    />
    <path
      d="M9.875 13.875H6.125C6.05625 13.875 6 13.9312 6 14V14.5C6 14.7766 6.22344 15 6.5 15H9.5C9.77656 15 10 14.7766 10 14.5V14C10 13.9312 9.94375 13.875 9.875 13.875ZM8 1C5.17031 1 2.875 3.29531 2.875 6.125C2.875 8.02187 3.90625 9.67812 5.4375 10.5641V12.375C5.4375 12.6516 5.66094 12.875 5.9375 12.875H10.0625C10.3391 12.875 10.5625 12.6516 10.5625 12.375V10.5641C12.0937 9.67812 13.125 8.02187 13.125 6.125C13.125 3.29531 10.8297 1 8 1ZM9.99844 9.59062L9.4375 9.91562V11.75H6.5625V9.91562L6.00156 9.59062C4.77187 8.87969 4 7.57031 4 6.125C4 3.91562 5.79062 2.125 8 2.125C10.2094 2.125 12 3.91562 12 6.125C12 7.57031 11.2281 8.87969 9.99844 9.59062Z"
      fill="#FA8C16"
    />
  </svg>
);

export default QuestionBulb;
