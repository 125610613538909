import { message } from "antd";
import { NoticeType } from "antd/es/message/interface";
import _ from "lodash";
import { ReactNode } from "react";
import { uuidV4 } from "@/utils/utils";

class Toast {
  static uniqueContent = (type: NoticeType, text: ReactNode) => {
    message[type]({ key: _.isString(text) ? text : uuidV4(), content: text });
  };

  static success = (text: ReactNode) => this.uniqueContent("success", text);

  static error = (text: ReactNode) => this.uniqueContent("error", text);

  static info = (text: ReactNode) => this.uniqueContent("info", text);

  static warning = (text: ReactNode) => this.uniqueContent("warning", text);

  static loading = (text: ReactNode) => this.uniqueContent("loading", text);
}

export default Toast;
