import { marked } from "marked";
import React from "react";

import styles from "./MessageMarkdown.module.scss";

interface Props {
  text?: string;
}
const MessageMarkdown: React.FC<Props> = ({ text = "" }) => {
  if (!text) return null;
  return (
    <div
      className={styles.markdown}
      dangerouslySetInnerHTML={{
        __html: marked.parse(text, { breaks: true }),
      }}
    ></div>
  );
};

export default MessageMarkdown;
