import { DeleteOutlined, LoadingOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import useUploadFile from "@/hooks/useUploadFile";

import styles from "./FileStatus.module.scss";

interface Props {
  file: any;
  onDelete?: any;
  onSuccess?: any;
}

const FileStatus: React.FC<Props> = ({ file: originFile, onDelete = _.noop, onSuccess = _.noop }) => {
  const { file, status, setFile } = useUploadFile();

  const isUploading = status === "processing" || status === "uploading";

  useEffect(() => {
    if (originFile) setFile(originFile);
  }, [originFile]);

  useEffect(() => {
    if (status === "done" || status === "warning") {
      onSuccess(file?.name);
    }
  }, [status]);

  const getCls = () => {
    let cls = styles.row || "";
    if (status === "error") cls = `${cls} ${styles.error}`;
    if (isUploading) cls = `${cls} ${styles.processing}`;
    return cls;
  };

  return (
    <Flex className={getCls()} justify="space-between">
      <div>
        <PaperClipOutlined />
        {file?.name}
      </div>
      {isUploading ? (
        <div>
          {status === "processing" ? "processing" : "uploading"} <LoadingOutlined />
        </div>
      ) : (
        <DeleteOutlined onClick={onDelete} />
      )}
    </Flex>
  );
};

export default FileStatus;
