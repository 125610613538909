import React from "react";

interface Props {
  style?: React.CSSProperties;
}

const UnApproveFilled: React.FC<Props> = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 14 14" fill="none">
    <g clipPath="url(#clip0_897_6029)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.27046 13.942C7.44246 14.0756 8.31252 13.0829 8.31253 12.0319V11.5939C8.31253 10.4316 8.87841 9.73624 9.46534 9.30732C9.75254 9.09745 10.0431 8.95429 10.2691 8.86236C10.5342 9.31842 11.0282 9.62503 11.5938 9.62503H12.4688C13.3144 9.62503 14 8.93946 14 8.09378L14 1.53124C14 0.685555 13.3144 -8.58307e-06 12.4687 -8.58307e-06H11.5937C10.9159 -8.58307e-06 10.3409 0.440467 10.1394 1.05087C9.68102 0.985411 9.20666 0.851085 8.63195 0.688345L8.62982 0.687741L8.5882 0.67596C8.46836 0.642029 8.34403 0.606832 8.21472 0.57093C7.205 0.290492 5.99148 0.000166893 4.375 0.000166893C2.88251 0.000166893 1.7766 0.13343 1.11869 0.996967C0.809725 1.40235 0.648813 1.90872 0.533488 2.46006C0.430884 2.95069 0.351714 3.54791 0.260138 4.2387L0.260051 4.23937L0.224526 4.50712C0.00656319 6.14196 -0.00734901 7.41033 0.389551 8.28343C0.603051 8.75298 0.933801 9.10822 1.38399 9.33356C1.8165 9.55005 2.31604 9.62513 2.84375 9.62513L4.08826 9.62513L4.07514 9.72343C4.01415 10.1769 3.9375 10.7467 3.9375 11.1564C3.9375 11.9627 4.06989 12.6602 4.51115 13.1684C4.95854 13.6838 5.60245 13.8659 6.27046 13.942ZM11.375 8.09378C11.375 8.2146 11.4729 8.31253 11.5938 8.31253H12.4688C12.5896 8.31253 12.6875 8.2146 12.6875 8.09378L12.6875 1.53124C12.6875 1.41049 12.5896 1.31249 12.4687 1.31249H11.5937C11.4729 1.31249 11.375 1.41049 11.375 1.53124V1.75017L11.375 8.09378Z"
        fill="black"
        fillOpacity="0.25"
      />
    </g>
    <defs>
      <clipPath id="clip0_897_6029">
        <rect width="14" height="14" fill="white" transform="matrix(-1 0 0 -1 14 14)" />
      </clipPath>
    </defs>
  </svg>
);

export default UnApproveFilled;
