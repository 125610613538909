import { RcFile } from "antd/es/upload";
import axios from "axios";
import _ from "lodash";
import {  useContext, useEffect, useState } from "react";
import AsyncTaskType from "@/components/constants/AsyncTaskType";
import ErrorCode from "@/components/constants/ErrorCode";
import { renderErrorNotification, renderToPayConfirm, renderWarningList } from "@/components/GlobalRenders";
import { getAsyncTaskUrl,  getFileCheckUrl,  getFilesUrl } from "@/components/url";
import UserInfoContext from "@/contexts/UserInfoContext";
import  { axiosError, axiosResponseHandler } from "@/utils/axiosError";
import { getFormatFileDataOption, isFreeUser, isOver20M, isOver50M } from "@/utils/common";
import { uuidV4 } from "@/utils/utils";
import { FileDataOption } from "./useGetFileList";

export type UploadFileFlowStatus = "uploading" | "processing" | "done" | "error" | "warning";

enum TaskStatus {
    STARTED = 1,
    SUCCESS = 2,
    FAILURE = 3,
}

export const ACCEPT = ".csv,.xls,.xlsx";

export interface WarningItem{
    title:string;
    text:string;
}

interface File extends RcFile{
  newName?:string
}

const useUploadFile = () => {
    const [file,setFile] = useState<File>();
    const [name,setName] = useState<string>(); // 校验通过的 filename
    const [fileData,setFileData] = useState<FileDataOption>();
    const [fileTaskId,setFileTaskId] = useState("");
    const [status,setStatus] = useState<UploadFileFlowStatus>();
    const [error,setError] = useState("");
    const [warningList,setWarningList] = useState<any>();

    const {user,updateUser} = useContext(UserInfoContext);

    const handleErrorCode = (r={} as any) =>{
        const {code} =r;
        if(ErrorCode.uploadFileErrors.includes(code)){
          if(ErrorCode.quotaExceededErrors.includes(code)){
            updateUser();// 刷新用户 quota 信息      
          }
          setError(ErrorCode.getText(code));
        }else{
          axiosError(r)
        }
        setStatus('error');
    }

    const getNewName = (name:string) =>{
      const end = ['.csv','.xls','.xlsx'].find(e => name.endsWith(e)) || "";
      const newName = name.replace(end,`_${uuidV4().slice(0,4)}${end}`)
      return newName;
    };

    const checkFile = (file:File,name:string) =>{
      const params:any = {name,size:file.size};
        axios.post(getFileCheckUrl(),params)
        .then(axiosResponseHandler)
        .then(() =>{
          setName(name)
        }).catch(r =>{
          const {code} =r;
          if(code === ErrorCode.FILE_NAME_DUPLICATED){
            checkFile(file,getNewName(file.name))
          }else {
            handleErrorCode(r)
          }
        })
    } 

    const validateBeforeUpload = () => {		
      if(!file) return ;
      if (isOver50M(file.size)) {		
        setError("The file is too large, please upload it again.")
        setStatus('error');
      } else if (isOver20M(file.size)) {		
        if (isFreeUser(user)) {		
          // to pay
          renderToPayConfirm();		
          setStatus('error');
        } else {		
          checkFile(file,file.name);
        }		
      } else {		
        checkFile(file,file.name);	
      }		
    };

    useEffect(() =>{
      if(file){
        setStatus("uploading")
        validateBeforeUpload();
      }
    },[file])

    // 1. start uploading
    useEffect(() =>{
        if(!file) return;
        if(name ){
            const formData = new FormData();
            formData.append("file", file);
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = () => {
              axios
                .post(getFilesUrl(), reader.result, {
                  withCredentials: true,
                  headers: { "Content-Disposition": `attachment;filename=${encodeURIComponent(name)}` },
                })
                .then(axiosResponseHandler)
                .then(r => {
                  const { data } = r.data;
                  setFileData(getFormatFileDataOption(data));
                })
                .catch(handleErrorCode);
            };
        }
    },[name]);

    // 2. create task
    useEffect(()=>{
        if(fileData){
            setStatus("processing");
            axios
            .post(getAsyncTaskUrl(), {
              params: { file_uid: fileData.uid },
              task_type: AsyncTaskType.PROCESS_FILE,
            })
            .then(axiosResponseHandler)
            .then(r => {
              const { code, data } = r.data;
              if (code) return Promise.reject({ code });
              setFileTaskId(data.uid);
            })
            .catch(handleErrorCode);
        }
    },[fileData])


    const pollingTask = () =>{
        axios
            .get(getAsyncTaskUrl(fileTaskId))
            .then(axiosResponseHandler)
            .then(r => {
              const { data } = r.data;
              const {status,result} =data;

              if (status === TaskStatus.SUCCESS) {
                const {data} = result;
                setStatus("done");
                if(!_.isEmpty(data)) setWarningList(data);
              } else if (status === TaskStatus.FAILURE) {
                const {code} = result;
                setStatus("error");
                setError(ErrorCode.getText(code) )
              } else {
                setTimeout(()=>{
                    pollingTask();
                },300);
              }
            }).catch(handleErrorCode);
    }

    // 3. polling task data
    useEffect(()=>{
        if(fileTaskId){
            pollingTask();
        }
    },[fileTaskId])

    useEffect(() => {
      if (warningList && !_.isEmpty(warningList)) {
        renderWarningList(warningList);
      }
    }, [warningList]);


    useEffect(() => {
      if (error) {
        renderErrorNotification({ title: "File Error", desc: error });
      }
    }, [error]);

    return {
        setFile:(file:any) =>{
            setFile(file);
            setName(undefined)
            setFileData(undefined);
            setFileTaskId("");
            setError("")  
            setStatus(undefined);
            setWarningList(undefined)
        },
        file,
        fileData,
        status,
        error,
    }
  };

  export default useUploadFile;