import { ConfigProvider } from "antd";
import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Loading from "./components/Loading";
import UserInfoContext, { UserInfoContextProvider } from "./contexts/UserInfoContext";
import { WebsocketContextProvider } from "./contexts/WebsocketContext";
import LayoutComponent from "./Layout";
import routes from "./routes";

import "./styles/reset.scss";
import "./styles/globalScrollbar.scss";

const App: React.FC = () => {
  return (
    <UserInfoContextProvider>
      <UserInfoContext.Consumer>
        {({ isLogin }) =>
          !isLogin ? (
            <Loading style={{ fontSize: "50px", marginTop: "200px" }} />
          ) : (
            <WebsocketContextProvider>
              <Router>
                <Routes>
                  {routes.map((r, rIndex) => {
                    const Ele = r.page;
                    return (
                      <Route
                        key={rIndex}
                        path={r.path}
                        element={
                          <ConfigProvider>
                            <LayoutComponent>
                              <Ele />
                            </LayoutComponent>
                          </ConfigProvider>
                        }
                      />
                    );
                  })}
                </Routes>
              </Router>
            </WebsocketContextProvider>
          )
        }
      </UserInfoContext.Consumer>
    </UserInfoContextProvider>
  );
};

export default App;
