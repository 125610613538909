import axios from "axios";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { getFilesUrl } from "@/components/url";
import { FileData } from "@/pages/Chats/components/AttachmentFile";
import  { axiosError, axiosResponseHandler } from "@/utils/axiosError";
import { getFormatFileDataOption } from "@/utils/common";

export interface FileDataOption extends FileData {
  label?: ReactNode;
  value?: string;
  key?: string;
}

interface Params {
  offset?: number;
  limit?: number;
  count?: number;
  q?: string;
}

const useGetFileList = ({ limit = 10 }) => {
  const [dataSource, setDataSource] = useState<FileDataOption[]>([]);
  const [total, setTotal] = useState(0);
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const cacheLoading = useMemo(() =>({loading:false}),[])

  const loadData = (params = {}) => {
    const { count = 0, offset = 0 } = params as Params;
    if(total && offset >= total) return;
    if (offset === 0) setLoading(true);
    else {
      if(cacheLoading.loading) return;
      cacheLoading.loading = true;
      setLoadMoreLoading(true);
    }
    axios
      .get(getFilesUrl(), { params: { q, offset, count, limit } })
      .then(axiosResponseHandler)
      .then((r: any) => {
        if (count) setTotal(r.data.data);
        else
          setDataSource((old: any) => {
            const res = r.data.data.map(getFormatFileDataOption);
            if (offset) return [...old, ...res];
            return res;
          });
      })
      .catch(axiosError)
      .finally(() =>{
        setLoading(false);
        cacheLoading.loading = false;
        setLoadMoreLoading(false)
      });
  };

  const updateData = () => {
    loadData({ count: 1 });
    loadData();
  };

  const loadMore = () => loadData({ offset: dataSource.length });

  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, q]);

  return {
    dataSource,
    total,
    updateData,
    loadMore,
    setQ,
    loading,
    loadMoreLoading:loadMoreLoading
  };
};

export default useGetFileList;
