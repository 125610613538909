import { Flex, Modal, Progress } from "antd";
import React, { useEffect, useState } from "react";

interface Props {
  time?: number;
  end?: boolean;
  start?: boolean;
  desc?: string;
  title?: string;
  error?: boolean;
}

const FakeProcessModal: React.FC<Props> = ({ title = " ", start = false, desc = "Uploading", time = 3000, end = false, error = false }) => {
  const [percent, setPercent] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!percent) return;

    let timer = undefined as any;
    timer = setTimeout(() => {
      setPercent(p => {
        const c = Math.floor(Math.random() * 10);
        return Math.min(p + c, 99);
      });
    }, time / 100);

    return () => clearTimeout(timer);
  }, [percent]);

  useEffect(() => {
    if (start && !percent) {
      setPercent(1);
      setOpen(true);
    }
  }, [start, percent]);

  useEffect(() => {
    if (end) {
      setPercent(100);
      setTimeout(() => {
        setOpen(false);
      }, 300);
    }
  }, [end]);

  return (
    <Modal centered open={open} closable={false} title={title} footer={null}>
      <div style={{ padding: "16px 24px 20px 24px", background: "rgba(0, 0, 0, 0.02)", borderRadius: "16px" }}>
        <Flex justify="space-between">
          <div style={{ fontSize: "16px", fontWeight: "500" }}>{desc}</div>
          <div style={{ fontSize: "16px", fontWeight: "500", color: "rgba(0, 0, 0, 0.45)" }}>{percent}%</div>
        </Flex>
        <Progress percent={percent} showInfo={false} status={error ? "exception" : end ? "success" : "active"} />
      </div>
    </Modal>
  );
};

export default FakeProcessModal;
