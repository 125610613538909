import axios, { AxiosResponse } from "axios";
import _ from "lodash";
import { ReactNode, useContext, useState } from "react";
import ErrorCode from "@/components/constants/ErrorCode";
import { getChatsUrl,getChatThreadsUrl } from "@/components/url";
import ChatsContext from "@/contexts/ChatsContext";
import UserInfoContext from "@/contexts/UserInfoContext";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";

const useCreateChat = () => {
  const { updateUser } = useContext(UserInfoContext);
  const { loadChats } = useContext(ChatsContext);
  const [loadingFileUid,setLoadingFileUid] =useState("");

    // create a chat
   const createChat = ({chatTitle="" as ReactNode,fileUid="",text="",successCallback=_.noop,isAutoCreate=false}) =>{
    setLoadingFileUid(fileUid);
    axios
    .post(getChatsUrl(), { title: chatTitle })
    .then(axiosResponseHandler)
    .then((r: AxiosResponse) => {
      loadChats();
      const chatUid = r.data.data.uid;

      // create a thread in chat
      const params:any = {};
      if(fileUid) params.files = [fileUid];
      if(text) params.text= text;
      if(isAutoCreate) params.is_auto_create = true;
      axios
        .post(getChatThreadsUrl(chatUid),params)
        .then(axiosResponseHandler)
        .then(() => {
          successCallback(chatUid)
        })
        .catch(r =>{
          if(ErrorCode.quotaExceededErrors.includes(r.code)) {
            updateUser();
          }
          axiosError(r);
        })
        .finally(() =>setLoadingFileUid(""));

    })
    .catch(r =>{
      if(ErrorCode.quotaExceededErrors.includes(r.code)) {
        updateUser();
      }
      axiosError(r);
    })
    .finally(() =>setLoadingFileUid(""));
   }

   return {
    createChat,
    loadingFileUid
   }
  };

  export default useCreateChat;