import { Modal } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { DocumentViewer } from "react-documents";
import Loading from "@/components/Loading";

interface Props {
  title?: string;
  url?: string;
  onCancel?: any;
}

import styles from "./index.module.scss";

const PPTXPreviewModal: React.FC<Props> = ({ title = "", url = "", onCancel = _.noop }) => {
  const [loading, setLoading] = useState(true);

  const loaded = () => {
    setLoading(false);
  };

  return (
    <Modal open centered wrapClassName={styles.pptModal} title={title} onCancel={onCancel} footer={null}>
      <DocumentViewer
        style={{ visibility: loading ? "hidden" : "visible", width: "100%", height: "100%" }}
        url={url}
        queryParams="hl=en"
        viewer="google"
        loaded={loaded}
      ></DocumentViewer>

      {loading && (
        <div className={styles.loading}>
          <h2> We&apos;re fetching your file ...</h2>
          <p>Please wait a moment </p>
          <Loading />
        </div>
      )}
    </Modal>
  );
};
export default PPTXPreviewModal;
