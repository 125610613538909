import React from "react";

interface Props {
  style?: React.CSSProperties;
}

const ChatOutlined: React.FC<Props> = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 10 10" fill="none">
    <path
      d="M9.61155 3.0625C9.35932 2.46317 8.99772 1.92522 8.53679 1.46317C8.07908 1.00379 7.53572 0.63864 6.93748 0.388393C6.32364 0.13058 5.67187 0 5 0H4.97768C4.30135 0.00334821 3.64622 0.137277 3.03016 0.40067C2.43704 0.653484 1.89877 1.01928 1.44536 1.47768C0.98889 1.93862 0.630636 2.47433 0.382871 3.07143C0.126178 3.68973 -0.00328486 4.3471 6.3317e-05 5.02344C0.00385038 5.79852 0.187217 6.56217 0.535771 7.25446V8.95089C0.535771 9.08705 0.58986 9.21764 0.686138 9.31392C0.782417 9.4102 0.912999 9.46429 1.04916 9.46429H2.74668C3.43896 9.81284 4.20261 9.99621 4.97768 10H5.00112C5.66964 10 6.31806 9.87054 6.92855 9.61719C7.52377 9.36992 8.0651 9.00903 8.52228 8.55469C8.98321 8.09821 9.34593 7.56473 9.59927 6.96987C9.86266 6.35379 9.99659 5.69866 9.99994 5.02232C10.0033 4.34263 9.87159 3.68304 9.61155 3.0625ZM7.92519 7.95089C7.14283 8.72545 6.1049 9.15179 5 9.15179H4.98103C4.30804 9.14844 3.63953 8.98103 3.04913 8.66629L2.95538 8.61607H1.38397V7.04464L1.33375 6.95089C1.01902 6.36049 0.851615 5.69196 0.848267 5.01897C0.843803 3.90625 1.26902 2.86161 2.04914 2.07478C2.82815 1.28795 3.86943 0.852679 4.98214 0.848214H5.00112C5.55915 0.848214 6.10043 0.956473 6.61047 1.17076C7.10823 1.37946 7.55466 1.67969 7.93858 2.06362C8.32139 2.44643 8.62272 2.89397 8.83143 3.39174C9.04794 3.90737 9.1562 4.45424 9.15397 5.01897C9.14727 6.13058 8.71089 7.17188 7.92519 7.95089Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default ChatOutlined;
