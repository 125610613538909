import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import { Flex, List, Modal } from "antd";
import axios from "axios";
import React, { ReactNode, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import AutoOverflowTip from "@/components/AutoOverflowTip";
import Loading from "@/components/Loading";
import ChatOutlined from "@/components/SVGComponents/ChatOutlined";
import Toast from "@/components/Toast";
import { getChatsUrl } from "@/components/url";
import ChatsContext from "@/contexts/ChatsContext";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";

import logo from "@/assets/png/flore.ai.png";

import styles from "./SideMenu.module.scss";

type MenuItem = {
  key: string;
  icon?: ReactNode;
  label: ReactNode;
  children?: MenuItem[];
};

const SideMenu: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { chatList = [], loadChats, loadMore, canLoadMore } = useContext(ChatsContext);

  const onDeleteAChat = (chatUid: string, chatTitle: string) => {
    Modal.confirm({
      title: `Delete chat?`,
      icon: null,
      content: (
        <>
          This will delete <strong>{chatTitle}</strong>?
        </>
      ),
      onOk: () => {
        axios
          .delete(getChatsUrl(chatUid))
          .then(axiosResponseHandler)
          .then(() => {
            navigate(chatList.filter(c => c.value !== chatUid)[0]?.value);
            Toast.success("Delete successfully!");
            loadChats();
          })
          .catch(axiosError);
      },
      okText: "Delete",
      okButtonProps: { danger: true },
    });
  };

  const items: MenuItem[] = [
    {
      key: "/files",
      icon: <FileTextOutlined />,
      label: "Files",
    },
    {
      key: "/chats",
      icon: <ChatOutlined />,
      label: "Chats",
    },
  ];

  const isSelectedItem = (uid = "") => `/chats/${uid}` === pathname;

  const renderMenuList = () => (
    <List className={styles.menu}>
      {items.map(c => (
        <List.Item className={`${pathname === c.key ? "selected" : ""}`} key={c.key} onClick={() => navigate(`${c.key}`)}>
          <Flex align="center" className="row">
            {c.icon}
            <div className={styles.overFlowText} style={{ fontSize: "16px", marginLeft: "6px" }}>
              {c.label}
            </div>
          </Flex>
        </List.Item>
      ))}
    </List>
  );

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "auto" }}>
      <div className={styles.layoutTitle} onClick={() => navigate("/")}>
        <img src={logo} alt="" style={{ height: "24px" }} />
      </div>
      {renderMenuList()}

      <div id="scrollableDivChatList" style={{ flex: 1, overflow: "auto" }}>
        <InfiniteScroll
          dataLength={chatList.length}
          next={loadMore}
          hasMore={canLoadMore}
          loader={<Loading />}
          endMessage={null}
          scrollableTarget="scrollableDivChatList"
        >
          <List className={styles.menuList}>
            {chatList.map(c => (
              <List.Item
                className={`${isSelectedItem(c.uid) ? "selected" : ""} ${c.is_active === false ? "unActive" : ""}`}
                key={c.uid}
                onClick={() => navigate(`/chats/${c.uid}`)}
              >
                <div className={styles.chatRow}>
                  <div style={{ flex: 1, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                    <AutoOverflowTip title={c.title}>c.title</AutoOverflowTip>
                  </div>
                  <DeleteOutlined
                    className={styles.delete}
                    onClick={e => {
                      e.stopPropagation();
                      onDeleteAChat(c.uid, c.title);
                    }}
                  />
                </div>
              </List.Item>
            ))}
          </List>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default SideMenu;
