// import axios from "axios";
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import LocalStorageKeys from "@/components/constants/LocalStorageKeys";
import { getFileQuestionsUrl } from "@/components/url";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";

enum Status {
  PENDING = 1, // 待处理
  GENERATING = 2, // 正在生成
  GENERATE_SUCCESS = 3, //  生成成功
  GENERATE_FAILED = 4, // 生成失败
}

const useGetFileQuestions = () => {
  const [fileUid, setFileUid] = useState<string>();
  const [questions, setQuestions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const localKey = `${LocalStorageKeys.FILE_QUESTION_CLICKED_LIST}_${fileUid}`;

  const loadQuestions = () => {
    if (!fileUid) return;
    setLoading(true);
    axios
      .get(getFileQuestionsUrl(fileUid))
      .then(axiosResponseHandler)
      .then((r: any) => {
        const { status, questions } = r.data.data || {};
        if ([Status.GENERATE_FAILED, Status.GENERATE_SUCCESS].includes(status)) {
          setQuestions(questions || []);
          setLoading(false);
        } else {
          setTimeout(() => {
            loadQuestions();
          }, 500);
        }
      })
      .catch(r => {
        axiosError(r);
        setLoading(false);
      });
  };

  useEffect(() => {
    fileUid && loadQuestions();
  }, [fileUid]);

  const markedQuestion = (text: string) => {
    const localString = localStorage.getItem(localKey);
    const data = localString ? JSON.parse(localString) : [];
    (data as string[]).push(text);
    localStorage.setItem(localKey, JSON.stringify(data));
  };

  const getUnClickedQuestions = () => {
    const localString = localStorage.getItem(localKey);
    const data = localString ? JSON.parse(localString) : [];
    return _.without(questions, ...data).slice(0, 3); // 只取 3 个
  };

  const updateFileUid = (fileUid: string) => {
    setFileUid(fileUid);
    setQuestions([]); // 清空上一个文件的推荐问题
  };

  return {
    updateFileUid,
    questions: getUnClickedQuestions(),
    markedQuestion,
    loading,
  };
};

export default useGetFileQuestions;
