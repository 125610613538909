import { LoadingOutlined } from "@ant-design/icons";
import { Spin, SpinProps } from "antd";
import React from "react";

const Loading: React.FC<SpinProps> = ({ style = {}, ...rest }) => {
  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: style.fontSize || 24 }} spin />}
      style={{ display: "block", textAlign: "center", margin: "10px", ...style }}
      {...rest}
    />
  );
};

export default Loading;
