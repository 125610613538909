import React from "react";
import { useParams } from "react-router-dom";
import Loading from "@/components/Loading";
import ChatInfoContext, { ChatInfoContextProvider } from "./ChatInfoContext";
import ChatTitle from "./components/ChatTitle";
import InputRow from "./components/InputRow";
import MessageList from "./components/MessageList";

import styles from "./index.module.scss";

const ChatContent: React.FC = () => {
  const { id } = useParams();

  return (
    <div key={id} className={styles.chatsWrapper}>
      <ChatInfoContextProvider>
        <ChatInfoContext.Consumer>
          {({ isLoading }) =>
            !isLoading ? (
              <>
                <div className={styles.title}>
                  <ChatTitle />
                </div>
                <MessageList />
                {<InputRow />}
              </>
            ) : (
              <>
                <Loading style={{ height: "30px", marginTop: "100px" }} />
              </>
            )
          }
        </ChatInfoContext.Consumer>
      </ChatInfoContextProvider>
    </div>
  );
};

export default ChatContent;
