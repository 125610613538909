import { CloseOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import _ from "lodash";
import React, { useContext, useState } from "react";
import GlobalFeedbackButton from "@/components/GlobalFeedbackButton";
import { renderPrice } from "@/components/GlobalRenders";
import UserInfoContext from "@/contexts/UserInfoContext";

import people from "@/assets/png/limitPeople.png";

import styles from "./SubscribeBanner.module.scss";

const SubscribeBanner: React.FC = () => {
  const { user } = useContext(UserInfoContext);
  const [closePPT, setClosePPT] = useState(false);
  const [closeUpload, setCloseUpload] = useState(false);

  const msg = {
    freePPTQuotaLimit: {
      title: "You've reached your free PPT quota on Flore.ai.",
      desc: "Enjoy larger file sizes and increased PPT quotas with the Standard Plan!",
    },
    freeUploadQuotaLimit: {
      title: "You've maxed out your free uploads.",
      desc: "To keep the magic going, let's upgrade!",
    },
    pptQuotaLimit: {
      title: "You've hit your 50 PPT limit for the month. Swing by again next month!",
      desc: "Thanks for choosing flore.ai!",
    },
    uploadQuotaLimit: {
      title: "You've maxed out your 50 Excel uploads this month.",
      desc: "To keep going, just hop back in next month!",
    },
  };

  const renderFeedbackBtn = () => <GlobalFeedbackButton style={{ position: "absolute", top: "-50px", right: "30px" }} />;

  const renderFreeLimitBanner = ({ title = "", desc = "" }) => (
    <Flex className={styles.freeBanner} justify="space-between" align="center">
      {renderFeedbackBtn()}
      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.desc}>{desc}</p>
      </div>
      {renderPrice({ width: "382px", marginTop: "0" })}
    </Flex>
  );

  const renderLimitBanner = ({ title = "", desc = "", isPPT = false }) => (
    <Flex className={styles.banner} justify="space-between" align="center">
      {renderFeedbackBtn()}
      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.desc}>{desc}</p>
      </div>
      <img src={people} alt="" style={{ marginRight: "88px" }} />
      <CloseOutlined className={styles.close} onClick={() => (isPPT ? setClosePPT(true) : setCloseUpload(true))} />
    </Flex>
  );

  if (_.isEmpty(user)) return null;

  if (!user.can_generate) {
    if (user.is_subscribe) return !closePPT && renderLimitBanner({ ...msg.pptQuotaLimit, isPPT: true });
    else return renderFreeLimitBanner(msg.freePPTQuotaLimit);
  }

  if (!user.can_upload) {
    if (user.is_subscribe) return !closeUpload && renderLimitBanner(msg.uploadQuotaLimit);
    else return renderFreeLimitBanner(msg.freeUploadQuotaLimit);
  }

  return null;
};

export default SubscribeBanner;
