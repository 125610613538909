import axios from "axios";
import LocalStorageKeys from "@/components/constants/LocalStorageKeys";
import { getUserPriceUrl } from "@/components/url";
import { FileData } from "@/pages/Chats/components/AttachmentFile";
import  { axiosError, axiosResponseHandler } from "./axiosError";

export const isOver20M = (size:number) => size > 1024 * 1024 * 20;

export const isOver50M = (size:number) => size > 1024 * 1024 * 50;

export const isFreeUser = (user:any) => !user.is_subscribe;

export const downloadFileFromUrl = (href:string,name="") => {
  const elm = document.createElement("a");
  elm.download = name;
  elm.href = href;
  elm.target = "blank";
  document.body.appendChild(elm);
  elm.click();
  elm.remove();
};
  

export const onUpgrade = () => {
  axios
    .post(getUserPriceUrl())
    .then(axiosResponseHandler)
    .then(r => {
      const url = r.data.data.url;
      localStorage.setItem(LocalStorageKeys.FORMER_LOCATION_URL,window.location.href)
      window.location.href = url;
    }).catch(axiosError);
};

export const getFormatFileDataOption = (d: FileData) =>({
  ...d,
  label: `${decodeURIComponent(d.file_name || "")}.${d.file_type}`,
  value: d.uid,
  key: d.uid,
});