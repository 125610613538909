import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Button, ConfigProvider, Popconfirm, theme } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AutoOverflowTip from "@/components/AutoOverflowTip";
import { getLogoutUrl } from "@/components/url";
import UserInfoContext from "@/contexts/UserInfoContext";
import { onUpgrade } from "@/utils/common";

import styles from "./Footer.module.scss";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { user = {} } = useContext(UserInfoContext);

  const onLogout = () => {
    window.location.href = getLogoutUrl();
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className={styles.menuFooter}>
        <Button className={styles.newChat} onClick={() => navigate("/")}>
          + New Chat
        </Button>
        {!user?.is_subscribe && (
          <div className={styles.trialWrapper}>
            {
              <div className={styles.flexRow}>
                <span style={{ fontSize: "12px" }}>FREE TRIAL</span>
                <div className={styles.flexRow}>
                  <Button className={styles.upgradeBtn} onClick={onUpgrade}>
                    Upgrade
                  </Button>
                </div>
              </div>
            }
          </div>
        )}
        <div className={styles.accountInfoWrapper}>
          <div className={styles.flexRow}>
            <Avatar style={{ width: "32px" }} src={user.avatar}></Avatar>
            <div style={{ marginLeft: "8px", flex: "1", overflow: "hidden" }}>
              <div>{user.nickname}</div>
              <div style={{ color: "rgba(255,255,255,0.45)", fontSize: "12px" }}>
                <AutoOverflowTip title={user.email}>{user.email}</AutoOverflowTip>
              </div>
            </div>
          </div>
          <Popconfirm title="Logout ?" placement="topRight" onConfirm={onLogout} okText="Yes" cancelText="No">
            <Button className={styles.settingsBtn} size="small" icon={<LogoutOutlined />} />
          </Popconfirm>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Footer;
