import Chats from "./pages/Chats";
import Files from "./pages/Files";
import FreshmanView from "./pages/FreshmanView";

export default [
  {
    path: "*",
    page: FreshmanView,
  },
  {
    path: "/files/",
    page: Files,
  },
  {
    path: "/chats/:id",
    page: Chats,
  },
];
