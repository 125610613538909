import React from "react";

interface Props {
  style?: React.CSSProperties;
}

const DemoFile: React.FC<Props> = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.45487 5.24998H2.625C2.00368 5.24998 1.5 5.75366 1.5 6.37498V14.625C1.5 15.2463 2.00368 15.75 2.625 15.75H15.375C15.9963 15.75 16.5 15.2463 16.5 14.625V6.37498C16.5 5.75366 15.9963 5.24998 15.375 5.24998H12.5451L14.4356 3.12325L13.3144 2.12671L10.5382 5.24998H7.4618L4.68556 2.12671L3.56444 3.12325L5.45487 5.24998ZM3 6.74998V14.25H15V6.74998H3ZM8.4375 9.77477V11.4707L9.70942 10.5521L8.4375 9.77477ZM6.9375 9.10613C6.9375 8.22758 7.89948 7.68807 8.64913 8.14619L10.9726 9.56608C11.6584 9.98518 11.6962 10.9675 11.0446 11.438L8.72118 13.1161C7.97718 13.6534 6.9375 13.1218 6.9375 12.2041V9.10613Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default DemoFile;
