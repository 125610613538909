const { host, origin, protocol } = window.location;

export const getV1Url = (path = "") => `/api/v1/${path}`;

export const getUserInfoUrl = () => getV1Url("users/info/");
export const getUserPriceUrl = () => getV1Url("users/pricing-table/");
export const getFeedbackUrl = () => getV1Url("feedbacks/");

export const getFilesUrl = (fileUid = "") => getV1Url(`files/${fileUid ? `${fileUid}/` : ""}`);
export const getFileQuestionsUrl = (fileUid = "") => getV1Url(`files/${fileUid}/questions/`);
export const getFileCheckUrl = () => getV1Url(`files/check/`);
export const getFileDownloadUrl = (fileUid = "") => getV1Url(`files/${fileUid}/download/`);
export const getChatsUrl = (uid = "") => getV1Url(`chats/${uid ? `${uid}/` : ""}`);
export const getChatMessagesUrl = (uid = "", mId = "") => getV1Url(`chats/${uid}/messages/${mId ? `${mId}/` : ""}`);
export const getChatMessageFeedbackUrl = (uid = "", mId = "") => getV1Url(`chats/${uid}/messages/${mId}/feedback/`);
export const getChatThreadsUrl = (chatUid = "", threadUid = "") => getV1Url(`chats/${chatUid}/threads/${threadUid ? `${threadUid}/` : ""}`);
export const getChatThreadMessageUrl = (chatUid = "", threadUid = "") => getV1Url(`chats/${chatUid}/threads/${threadUid}/messages/`);

export const getWebsocketUrl = () => `${protocol === "https:" ? "wss" : "ws"}://${host}/ws/event/`;
export const getAsyncTaskUrl = (id = "") => getV1Url(`tasks/${id ? `${id}/` : ""}`);

export const getLoginUrl = () => `${origin}/login`;
export const getLogoutUrl = () => `${origin}/logout`;
