import axios from "axios";
import _ from "lodash";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { getChatsUrl } from "@/components/url";
import { ChatInfo } from "@/pages/Chats/ChatInfoContext";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";
import { getCacheValue } from "@/utils/utils";

interface ContextValues {
  chatList: any[];
  loadMore: () => void;
  loadChats: () => void;
  updateTitle: (title?: string, chatUid?: string) => void;
  canLoadMore: boolean;
  loading: boolean;
}
const ChatsContext = React.createContext<ContextValues>({
  chatList: [],
  loadChats: _.noop,
  updateTitle: _.noop,
  loadMore: _.noop,
  canLoadMore: true,
  loading: true,
});

export default ChatsContext;

interface IProps {
  children?: ReactNode;
}

const LIMIT = 50;

export const ChatsContextProvider: React.FC<IProps> = ({ children }) => {
  const [chatList, setChatList] = useState<ChatInfo[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const cacheLoading = useCallback(getCacheValue(false), []);

  const loadAChatInfo = (id: string) => {
    axios
      .get(getChatsUrl(id))
      .then(axiosResponseHandler)
      .then(r => {
        const newInfo = r.data.data as ChatInfo;
        setChatList(list => {
          return list.map(l => (l.uid === id ? { ...l, is_active: newInfo.is_active } : l));
        });
      })
      .catch(axiosError);
  };

  const loadChats = (offset = 0) => {
    if (cacheLoading()) return;
    cacheLoading(true);
    setLoading(true);
    axios
      .get(getChatsUrl(), { params: { offset, limit: LIMIT } })
      .then(axiosResponseHandler)
      .then(r => {
        const list: ChatInfo[] = r.data.data || [];
        setChatList(prev => (offset ? prev.concat(list) : list));
        list.forEach(c => c.is_active && loadAChatInfo(c.uid));
      })
      .catch(axiosError)
      .finally(() => {
        cacheLoading(false);
        setLoading(false);
      });
  };

  const loadTotal = () => {
    axios
      .get(getChatsUrl(), { params: { count: 1 } })
      .then(axiosResponseHandler)
      .then(r => setTotal(r.data.data))
      .catch(axiosError);
  };

  const loadMore = () => {
    loadChats(chatList.length);
  };

  const updateChats = () => {
    loadChats();
    loadTotal();
  };

  useEffect(updateChats, []);

  const updateTitle = (title = "", uid = "") => {
    if (!title || !uid) return;
    setChatList(list => {
      return list.map(c => (c.uid === uid ? { ...c, title } : c));
    });
  };

  return (
    <ChatsContext.Provider
      value={{
        chatList,
        loadChats: updateChats,
        updateTitle,
        loadMore,
        canLoadMore: chatList.length < total,
        loading,
      }}
    >
      {children}
    </ChatsContext.Provider>
  );
};
