import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Flex, Input, Popover } from "antd";
import axios from "axios";
import React, { useState } from "react";
import Toast from "@/components/Toast";
import { getFeedbackUrl } from "@/components/url";
import { axiosError, axiosResponseHandler } from "@/utils/axiosError";

import DiscordSrc from "@/assets/svg/discord.svg";

interface Props {
  style?: React.CSSProperties;
}

import styles from "./index.module.scss";

const GlobalFeedbackButton: React.FC<Props> = ({ style = {} }) => {
  const [open, setOpen] = useState(false);
  const [v, setV] = useState("");

  const onJoin = () => {
    window.open("https://discord.gg/XyvvPwaT", "_blank");
  };

  const onSubmit = () => {
    axios
      .post(getFeedbackUrl(), { feedback: v })
      .then(axiosResponseHandler)
      .then(() => {
        Toast.success("Feedback submit successfully!");
        setOpen(false);
        setV("");
      })
      .catch(axiosError);
  };

  const content = (
    <div>
      <Flex vertical gap="8px" align="flex-start">
        <div className={styles.label}>Anything that can be improved?</div>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorBorder: "transparent",
                colorBgContainer: "rgba(0, 0, 0, 0.05)",
              },
            },
          }}
        >
          <Input.TextArea rows={4} style={{ marginTop: "8px" }} onChange={e => setV(e.target.value)} />
        </ConfigProvider>

        <Button type="primary" disabled={!v} onClick={onSubmit}>
          Submit
        </Button>
      </Flex>
      <Flex vertical gap="6px" align="flex-start" style={{ marginTop: "20px" }}>
        <div className={styles.label}>Want to discuss with others?</div>
        <div className={styles.help}>Join our Discord community please!</div>
        <ConfigProvider theme={{ token: { colorPrimary: "#5865F2" } }}>
          <Button
            type="primary"
            style={{ display: "flex", alignItems: "center", width: "32px!important" }}
            icon={<img src={DiscordSrc} alt="" />}
            onClick={onJoin}
          >
            Join Discord Server
          </Button>
        </ConfigProvider>
      </Flex>
    </div>
  );

  return (
    <Popover
      trigger="click"
      arrow={false}
      placement="topRight"
      title="Feedback"
      content={content}
      open={open}
      onOpenChange={setOpen}
      rootClassName={styles.pop}
    >
      <Button
        className={styles.triggerBtn}
        style={style}
        icon={open ? <CloseOutlined style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.45)" }} /> : <QuestionCircleOutlined />}
      />
    </Popover>
  );
};
export default GlobalFeedbackButton;
